<script setup lang="ts">
const props = defineProps({
  playOnScroll: { type: Boolean, required: false },
  animationData: { type: Object, required: true },
  animationClass: { type: String, required: false },
})

const lottiePlayed = ref(false)
const lottieAnimation = ref()
const animationScrollTrigger = ref()

const isElementInViewport = (el: Element) => {
  var rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const handleScroll = () => {
  if (lottieAnimation.value && animationScrollTrigger.value && !lottiePlayed.value) {
    if (isElementInViewport(animationScrollTrigger.value)) {
      lottieAnimation.value.play()
      lottiePlayed.value = true
    }
  }
}

if (process.client) {
  window.addEventListener('scroll', handleScroll)
}

onBeforeUnmount(() => {
  if (process.client) {
    window.removeEventListener('scroll', handleScroll)
  }
})
</script>

<template v-if="animationData">
  <div :class="animationClass + ' pointer-events-none'">
    <Vue3Lottie
      :animation-data="animationData"
      ref="lottieAnimation"
      :autoPlay="!playOnScroll"
      :loop="false"
    />
  </div>
  <div
    ref="animationScrollTrigger"
    class="absolute top-0 left-0 w-1 h-1 pointer-events-none opacity-0"
  />
</template>
